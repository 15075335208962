import React from "react";
import Title, { TitleProps } from "./Title";
import TitleTag from "./TitleTag";

interface TitleHeadingProps {
  tag: string;
  title: string | React.ReactNode;
  titleType?: TitleProps["type"];
  theme?: "white" | "dark" | "gray" | "indigo";
  center?: boolean;
  mobileCenter?: boolean;
  bgTag?: boolean;
}

export const TitleHeading: React.FunctionComponent<TitleHeadingProps> = ({
  tag,
  title,
  titleType,
  theme = "white",
  mobileCenter = false,
  center = false,
  bgTag = false,
}) => {
  return (
    <div
      className={`relative ${
        center ? "text-center" : mobileCenter ? "text-center md:text-left" : ""
      }`}
    >
      {bgTag && (
        <span
          className={`text-8xl lg:text-9xl ${
            theme === "dark" || theme === "indigo"
              ? "text-gray-800"
              : theme === "gray"
                ? "text-gray-200"
                : "text-gray-100"
          } font-extrabold absolute w-screen h-full  -left-5 sm:left-0 sm:w-full z-10 whitespace-nowrap flex ${
            center ? "justify-center sm:left-0" : "md:-left-10 justify-start"
          } items-center _overflow-x-clip uppercase`}
        >
          <span>{tag}</span>
        </span>
      )}
      <div className={bgTag ? `relative z-20 my-10` : ""}>
        <TitleTag>{tag}</TitleTag>
        <Title
          xlarge
          dark={theme === "dark" || theme === "indigo"}
          className="mb-0"
          type={titleType}
        >
          {title}
        </Title>
      </div>
    </div>
  );
};
